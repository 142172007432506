
import he from 'he';
import {mapMutations, mapState} from "vuex";
import FocusDirective from '~/directives/focusDirective';

export default {
  name: 'SearchAutocomplete',
  data() {
    return {
      input: "",
      results: [],
      isOpen: false,
      class: null,
      displayForm : false
    }
  },
  mounted() {
    this.init()
    document.addEventListener('click', this.handleClickOutside)
  },
  destroyed() {
    document.removeEventListener('click', this.handleClickOutside)
  },
  directives: {
    focus: FocusDirective,
  },
  methods:{
    async init() {
      await this.$store.dispatch('search/init')
    },
    async submit(event) {
      if(event){
        event.preventDefault()
        this.$router.push("/recherche?keyword="+this.input);
        this.isOpen = false;
        this.setSearchBarVisibility()
      }
    },
    decode (value) {
      return he.decode(value)
    },
    async filterResults() {
      this.results = await this.$store.dispatch('show/searchShow', this.input);
    },
    setResult(result) {
      this.input = this.decode(result.title);
      this.isOpen = false;
      this.$router.push(result.url)
      this.input = ''
      this.closeSearchBar()
    },
    async onChange(){
      if (this.input.length >= 3)
      {
        await this.filterResults();
        this.isOpen = this.results.length > 0;
      }
      else {
        this.isOpen = false;
      }
    },
    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.isOpen = false;
      }
    },
    setSearchBarVisibility() {
      this.displayForm = false
      if (!this.displayForm) {
        this.input = ""
        this.isOpen = false
      }
      this.closeSearchBar()
      this.$nextTick(() => this.$refs.search_input.focus())
    },
    focusSearchInput() {
      const inputElement = this.$refs.search_input;
      if (inputElement) {
        inputElement.focus();
      }
    },
    ...mapMutations({
      closeSearchBar: 'header/closeSearchBar',
    }),
  },
  computed: {
    ...mapState('header', {
      displaySearchBar: state => state.displaySearchBar,
    })
  }
}
